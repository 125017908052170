





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import StatsBar from '@/components/StatsBar.vue';
import StatsBarDouble from '@/components/StatsBarDouble.vue';
import { MutableAttributesName } from '@/type';

@Component({
    components: {
        StatsBar,
        StatsBarDouble
    }
})
export default class StatsBarFull extends Vue {
    @Prop()
    points!: number

    @Prop()
    max!: number

    @Prop()
    littleFont!: boolean

    @Prop()
    veryLittleFont!: boolean

    @Prop()
    pointsDouble!: number

    @Prop()
    type!: MutableAttributesName

    @Prop()
    noText!: boolean

    @Prop()
    tooltipText!: string

    @Prop({ default: 800 })
    startAfter!: number
    

    @Prop()
    customStyle!: Record<string, string>

    @Prop()
    rounded!: boolean


    displayedPoint = 0

    intervalId: number | null =  null

    maxTime = 1000
    intervalBetwInc = 100

    get typeText () {
        return this.type ? this.type.replace('_', ' ') : ''
    }

    get roundedDisplay () {
        return (this.displayedPoint / Math.pow(10, 6)).toFixed(1) + 'M'
    }

    get maxRounded () {
        return (this.max / Math.pow(10, 6)).toFixed(1) + 'M'
    }

    getFontClass () {
        if (this.littleFont) {
            return 'lit-font pad-top-stats'
        } else if (this.veryLittleFont) {
            return 'very-lit-font pad-top-stats'
        }
        return ''
    }

    getStyleFont () {
        return this.customStyle ? this.customStyle : null
    }

    get tooltip () {
        if (this.tooltipText) {
            return this.tooltipText
        }
        return `${this.points} / ${this.max}`
    }

    @Watch('points', {immediate: true})
    startIncrement(points: number) {
        if (points) {
            const inc: number = Math.max(Math.round(this.points / (this.maxTime/this.intervalBetwInc)), 1)
            setTimeout(() => {
                this.intervalId = window.setInterval(() => {
                    if (this.displayedPoint + inc >= this.points) {
                        this.displayedPoint = this.points
                        
                        if (this.intervalId) {
                            window.clearInterval(this.intervalId)
                        }
                    } else {
                        this.displayedPoint += inc
                    }
                }, this.intervalBetwInc)
            }, this.startAfter)
        }
    }
}
