import { Attributes, Family, JsonMission, MissionConfig, MissionMode, RewardsType, TrooperzAttributes } from "@/type"
import axios from "axios"
import { calcBonusTcoin, convertValueFromContract, getBonuxTcoinForLvl, getIpfsPath, getLvlWithXp } from "./utilities"

export async function getJsonMission(ipfs_link: string) {
    const missionConfigPath = getIpfsPath(ipfs_link)
    const jsonMission: JsonMission = (await axios.get(missionConfigPath)).data
    if (!jsonMission) {
        throw Error('Can not find json')
    }
    return jsonMission
}

export function convertByBlockToByHours(byBlock: number, timeBlock: number) {
    const blockMinutes = 60 / timeBlock
    const blockHours = blockMinutes * 60
    return byBlock * blockHours
}

export function tcoinByBlockToByHours(tcoinByBlock: number, timeBlock: number) {
    return convertByBlockToByHours(tcoinByBlock, timeBlock)
}

export function getRoiByHours(tcoinByBlock: number, timeBlock: number, xp: number, nbDigitTcoin: number, isUnique = false) {
    const lvl = getLvlWithXp(xp)
    const tcoinBlockNormalized = convertValueFromContract(tcoinByBlock, nbDigitTcoin)
    let rewardsPerBlock = calcBonusTcoin(tcoinBlockNormalized, lvl)
    if (isUnique) {
        rewardsPerBlock = rewardsPerBlock * 2
    }
    const infoRend = tcoinByBlockToByHours(rewardsPerBlock, timeBlock)
    return infoRend
}

export function getInforRewardsTcoin (tcoinByBlock: number, xp: number, nbDigitTcoin: number, isUnique = false) {
    const lvl = getLvlWithXp(xp)
    const tcoinBlockNormalized = convertValueFromContract(tcoinByBlock, nbDigitTcoin)
    const bonus = getBonuxTcoinForLvl(lvl)
    let message = `Result based on ${tcoinBlockNormalized} / block + lvl ${lvl} bonus (x${1 + bonus})`
    if (isUnique) {
        message += ` + unique bonus (x2)`
    }

    message += '.'
    return message
}

export function isMissionEnded (mission: MissionConfig, currentBlock: number) {
        return Number(mission.start_block) + Number(mission.duration) < currentBlock
}

export function getEndBlockMission (mission: MissionConfig) {
    return Number(mission.start_block) + Number(mission.duration)
}

export function isMissionEndedDate (mission: MissionConfig): boolean {
    return !!(mission.end_mission && (mission.end_mission.getTime()) < (new Date().getTime()))
}

export function getEndMissionDate (timeBlock: number, currentBlock: number | null, mission: MissionConfig) {
    if (!currentBlock) {
        console.warn('no current block data')
        return null
    }
    const endBlockMission = Number(mission.start_block) + Number(mission.duration)
    const remainingBlock = endBlockMission - currentBlock
    return getDateWithRemainingBlock(timeBlock, remainingBlock)
}

export function getDateWithRemainingBlock (timeBlock: number, remainingBlock: number) {
    const remainingSeconds = remainingBlock * timeBlock
    const remainingMilliseconds = remainingSeconds * 1000
    const now = new Date().getTime()
    return new Date(now + remainingMilliseconds)
}

export function checkStats (trooperzStats: number, stats?: string | null) {
    if (!stats) {
        return true
    }
    return Number(stats) <= trooperzStats
}

export function checkLevel (lvl: number, min_lvl?: string | null) {
    if (!min_lvl) {
        return true
    }
    return Number(min_lvl) <= lvl
}

export function checkFamily (family: Family, allowedFamily: Family[] | null) {
    if (!allowedFamily) {
        return true
    }
    return family === 'tcorp' || allowedFamily.includes(family)
}

export function checkMissionRequirements(lvl: number, trooperzAttributes: TrooperzAttributes, mission: MissionConfig) {
    if (!checkLevel(lvl, mission.min_lvl)) {
        return false
    } else if (mission.should_have_pets && !trooperzAttributes.has_pet) {
        return false
    } else if (!checkStats(trooperzAttributes.mutable_attributes.trickery, mission.min_trickery)) {
        return false
    } else if (!checkStats(trooperzAttributes.mutable_attributes.fire_arms, mission.min_fire_arms)) {
        return false
    } else if (!checkStats(trooperzAttributes.mutable_attributes.hacking, mission.min_hacking)) {
        return false
    } else if (!checkStats(trooperzAttributes.mutable_attributes.close_combat, mission.min_close_combat)) {
        return 
    } else if (!checkFamily(trooperzAttributes.family, mission.allowed_family)) {
        return false
    }
    return true
}

export function getFailedMissionRequirements (lvl: number, trooperzAttributes: TrooperzAttributes, mission: MissionConfig) {
    const failedRequirements = []

    if (!checkLevel(lvl, mission.min_lvl)) {
        failedRequirements.push('min_lvl')
    } 
    if (mission.should_have_pets && !trooperzAttributes.has_pet) {
        failedRequirements.push('should_have_pets')
    } 
    if (!checkStats(trooperzAttributes.mutable_attributes.trickery, mission.min_trickery)) {
        failedRequirements.push('min_trickery')
    } 
    if (!checkStats(trooperzAttributes.mutable_attributes.fire_arms, mission.min_fire_arms)) {
        failedRequirements.push('min_fire_arms')
    } 
    if (!checkStats(trooperzAttributes.mutable_attributes.hacking, mission.min_hacking)) {
        failedRequirements.push('min_hacking')
    } 
    if (!checkStats(trooperzAttributes.mutable_attributes.close_combat, mission.min_close_combat)) {
        failedRequirements.push('min_close_combat')
    } 
    if (!checkFamily(trooperzAttributes.family, mission.allowed_family)) {
        failedRequirements.push('allowed_family')
    }
    return failedRequirements
}