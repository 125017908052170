import { BigNumber, MissionConfig, MissionMode, RewardsInfo, TrainingConfig, WithKey } from "@/type"
import { getEndBlockMission } from "./missionHelper"
import { calcBonusTcoin, convertValueFromContract, getLvlWithXp } from "./utilities"

export function calcTrooperzRewards(mode: MissionMode, startBlock: BigNumber, currentBlock: number, 
    trainingConfig: TrainingConfig, energy: number, xp: number,
    maxEnergy: number, maxXp: number, mission: WithKey<MissionConfig> | null, isUnique: boolean, nbDigitTcoin: number): RewardsInfo | null    {

    let maxBlock = currentBlock

    if (mode === 'mission') {
        if (mission) {
            maxBlock = Math.min(maxBlock, getEndBlockMission(mission))
        } else {
            console.warn('[calcTrooperzRewards] Can not find mission')
        }
    }

    const diff = maxBlock - startBlock
    let rewardsPerBlock 
    let maxDiff = diff
    let maxRewards = null
    let energyCostPerBlock = null
    if (mode === 'rest') {
        rewardsPerBlock = trainingConfig.energy_by_block
        maxRewards = maxEnergy - energy
    } else if (mode === 'train') {
        maxRewards = maxXp - xp
        maxDiff = Math.floor(energy / trainingConfig.train_energy_cost_by_block)
        energyCostPerBlock = trainingConfig.train_energy_cost_by_block
        rewardsPerBlock = trainingConfig.xp_by_block
    } else if (mode === 'mission') {
        maxDiff = Math.floor(energy / trainingConfig.mission_energy_cost_by_block)
        energyCostPerBlock = trainingConfig.mission_energy_cost_by_block

        if (mission) {
            const lvl = getLvlWithXp(xp)
            const tcoinBlockNormalized = convertValueFromContract(Number(mission.tcoin_by_block), nbDigitTcoin)
            rewardsPerBlock = calcBonusTcoin(tcoinBlockNormalized, lvl)
            if (isUnique){
                // unique got * 2 
                rewardsPerBlock = rewardsPerBlock * 2
            }
        } else {
            console.warn('[calcTrooperzRewards] Can not find mission')
        }
    }
    if (!rewardsPerBlock) {
        console.warn('[calcTrooperzRewards] Can not define rewards per block')
        return null
    }
    const finalDiff = Math.min(diff, maxDiff)
    const rewards = rewardsPerBlock * finalDiff

    let remainingBlock = maxDiff - diff
    if (mode === 'rest' && maxRewards) {
        const energyToGain = maxRewards - rewards
        remainingBlock = energyToGain / trainingConfig.energy_by_block
    }

    return {
        remainingBlock: remainingBlock,
        rewards: maxRewards !== null && maxRewards !== undefined ? Math.min(rewards, maxRewards) : rewards,
        energyCost: mode === 'rest' ? 0 : energyCostPerBlock * finalDiff,
        isOnMaxRewards: isOnMaxRewards(mode, maxRewards, maxDiff, rewards, finalDiff)
    }
}

function isOnMaxRewards (mode: MissionMode, maxRewards: null | number, maxDiff: number, rewards: number, finalDiff: number) {
    return (mode === 'rest' && maxRewards !== null && maxRewards !== undefined && rewards >= maxRewards) || (mode !== 'rest' && maxDiff === finalDiff)
}