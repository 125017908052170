import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { getIpfsPath, getLvlWithXp, getReqForLvl, detectMobile } from '../utils/utilities'

@Component
export default class HasXp extends Vue {
    xpCurrentLvl (xp: number) {
        const lvl = this.getLevel(xp)
        const reqLvl = getReqForLvl(lvl)
        return xp - reqLvl
    }

    getReqNextLvl (xp: number) {
        const lvl = this.getLevel(xp)
        return getReqForLvl(lvl + 1)
    }

    getStatsMax (xp: number) {
        const lvl = this.getLevel(xp)
        const reqNext = this.getReqNextLvl(xp)
        const reqLvl = getReqForLvl(lvl) 
        return reqNext - reqLvl
    }

    getLevel (xp: number) {
        return getLvlWithXp(xp)
    }
}


