import { TrooperzApiResult } from "@/type";
import axios from "axios";

const BASE_URL = 'https://trooperz-api-dot-trooperz.ew.r.appspot.com/'
const BASE_URL_STAGING = 'https://trooperz-api-staging-dot-trooperz.ew.r.appspot.com/'

// const BASE_URL = 'http://127.0.0.1:5000/'

export async function getTrooperzById (tokenId: number): Promise<TrooperzApiResult[]> {
    const url = createUrl('trooperz', tokenId)
    return (await axios.get(url)).data.data
}

export async function getTrooperzList (limit: number, offset: number, search?: string | null, args?: {[key: string]: any}): Promise<{
    data: TrooperzApiResult[],
    count: number
}> {
    const url = trooperzUrl('trooperz')
    return (await axios.get(url, {
        params: {
            limit: limit,
            offset: offset,
            search: search,
            ...args
        }
    })).data
}



function createUrl (ressource: string, args: any) {
    return `${getBaseUrl()}${ressource}/${args}`
}

function trooperzUrl (ressource: string)  {
    return `${getBaseUrl()}${ressource}`
}

function getBaseUrl () {
    const env = process.env.VUE_APP_ENV
    if (env == 'prod') {
        return BASE_URL
    } else if (env == 'staging') {
        return BASE_URL_STAGING
    }
    throw Error(`Not implemented env for trooperz api base url ${env}`)
}


