










import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class StatsBar extends Vue {
    @Prop()
    points!: number

    @Prop()
    max!: number

    @Prop()
    tooltipText!: string

    @Watch('points', {immediate: true})
    onChangePoint () {
        this.$nextTick(() => {
            let bar = this.$refs['stats-bar-inner']
            if (bar) {
                let newWidth = (this.points / this.max) * 100;
                (bar as HTMLBaseElement).style.width = newWidth + '%'
            }

        })
    }

    get tooltip () {
        if (this.tooltipText) {
            return this.tooltipText
        }
        return `${this.points} / ${this.max}`
    }
    
}
