












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        
    }
})
export default class BuyBtn extends Vue {
    @Prop()
    label!: string

    @Prop()
    href!: string

    emitClick () {
        this.$emit('clickBuy')
    }
}
